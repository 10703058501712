import cx from 'classnames';
import { useContext, useState } from 'react';

import type { ConnectedDevice } from '@sb/assembly';
import {
  Button,
  Menu,
  MenuItem,
  MenuSeparator,
  Popper,
} from '@sb/design-system';
import { JointType } from '@sb/firmware-interface/types';
import { JOINT_NAMES } from '@sbrc/utils';

import { AssemblyContext } from './AssemblyContext';

type Props = {
  selected: ConnectedDevice | null;
  onSelect: (device: ConnectedDevice) => void;
};

export function AssemblyDeviceDropDown(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { selected, onSelect } = props;
  const { connectedDevices } = useContext(AssemblyContext);

  const motorJoints: ConnectedDevice[] = [];
  const otherJoints: ConnectedDevice[] = [];

  connectedDevices.forEach((device) => {
    switch (device.jointType) {
      case JointType.ShoulderJoint:
      case JointType.ElbowJoint:
      case JointType.WristJoint:
        motorJoints.push(device);
        break;
      default:
        otherJoints.push(device);
        break;
    }
  });

  const getMenuItem = (device: ConnectedDevice) => {
    const { jointNumber, serialNumber, jointType } = device;

    return (
      <MenuItem
        className={cx('tw-py-8', {
          'tw-surface-blue': selected?.serialNumber === serialNumber,
        })}
        key={serialNumber}
        onClick={() => onSelect(device)}
      >
        <div className="tw-flex tw-items-center tw-justify-between">
          <span>{JointType[jointType]}</span>
          <span>
            {jointNumber !== undefined && jointNumber >= 0 && jointNumber <= 5
              ? `${JOINT_NAMES[jointNumber]}`
              : ''}
          </span>
        </div>
      </MenuItem>
    );
  };

  return (
    <Popper
      className="tw-w-256 tw-flex"
      placement="bottom-start"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={
        <Menu className="tw-w-256">
          {motorJoints.map((device) => getMenuItem(device))}
          {otherJoints.length > 0 && (
            <>
              <MenuSeparator />
              {otherJoints.map((device) => getMenuItem(device))}
            </>
          )}
        </Menu>
      }
      data-testid="robot-badge"
    >
      <Button
        disabled={connectedDevices.length === 0}
        className="tw-flex-1"
        onClick={() => setIsOpen(!isOpen)}
      >
        {connectedDevices.length === 0 && <span>No Deviced Connected</span>}
        {!selected && connectedDevices.length > 0 && <span>Select Device</span>}
        {selected && (
          <div className="tw-flex tw-w-full tw-justify-between">
            <span>{JointType[selected.jointType]}</span>
            <span>
              {selected.jointNumber !== undefined
                ? `J${selected.jointNumber}`
                : selected.serialNumber}
            </span>
          </div>
        )}
      </Button>
    </Popper>
  );
}
