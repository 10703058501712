import cx from 'classnames';
import { startCase, upperFirst, round, cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import type { AssemblyRunnableDetails, ConnectedDevice } from '@sb/assembly';
import { getRunnablesByJointType } from '@sb/assembly';
import { SettingsGroup, SettingsGroupNavigationItem } from '@sb/design-system';
import { getAssemblyControllerHandle } from '@sbrc/services/assembly-client';

import { AssemblyContext } from './AssemblyContext';
import { AssemblyOutputPanel } from './AssemblyOutputPanel';
import { AssemblyRunPanel } from './AssemblyRunPanel';

type OutputState = {
  [key: string]: { runId: string; output: string[] }[];
};

type Props = {
  device: ConnectedDevice;
};

export function AssemblyDeviceDetails(props: Props) {
  const { device } = props;
  const assemblyConnection = getAssemblyControllerHandle();
  const { jointStates } = useContext(AssemblyContext);
  const [runnableOutput, setRunnableOutput] = useState<OutputState>({});

  const [selectedAction, setSelectedAction] =
    useState<AssemblyRunnableDetails | null>(null);

  const jointState =
    device.jointNumber !== undefined ? jointStates[device.jointNumber] : null;

  useEffect(() => {
    const unsubscribeRunnableOutput =
      assemblyConnection.listenForRunnableOutput((data) => {
        setRunnableOutput((prevOutput: OutputState) => {
          const newOutput = cloneDeep(prevOutput);

          if (!newOutput[data.runnableKey]) {
            newOutput[data.runnableKey] = [];
          }

          let runData = newOutput[data.runnableKey].find(
            (run) => run.runId === data.runId,
          );

          if (!runData) {
            runData = {
              runId: data.runId,
              output: [],
            };

            newOutput[data.runnableKey].push(runData);
          }

          runData.output.push(data.message);

          return newOutput;
        });
      });

    return () => {
      unsubscribeRunnableOutput();
    };
  }, [assemblyConnection]);

  const runnables = getRunnablesByJointType(device.jointType);

  return (
    <div className="tw-h-full">
      <div className="tw-w-full tw-flex mobile:tw-flex-col tw-gap-24 tw-h-full tw-border-b tw-pb-8">
        <div>
          <h2 className="tw-text-30 tw-text-center tw-w-full tw-block tw-mt-24 tw-mb-0 tw-pb-0">
            {upperFirst(startCase(`Joint ${device.jointNumber}`))}
          </h2>
          <span className="tw-text-center tw-w-full tw-block tw-mt-8 tw-text-label-secondary">
            Serial #{device.serialNumber}
          </span>
        </div>
        <div className="tw-flex tw-flex-col tw-h-full">
          <div className="tw-flex tw-gap-8 tw-items-center">
            <h5>Brake:</h5>
            <span className="tw-text-label-secondary">
              {jointState?.brake === true ? (
                <span className="tw-text-red">Engaged</span>
              ) : (
                <span className="tw-text-green">Released</span>
              )}
            </span>
          </div>
          <div className="tw-flex tw-gap-8 tw-items-center">
            <h5>Position:</h5>
            <span className="tw-text-label-secondary">
              {jointState?.position !== undefined
                ? round(jointState.position, 5).toFixed(5)
                : '?'}
            </span>
          </div>
          <div className="tw-flex tw-gap-8 tw-items-center">
            <h5>Velocity:</h5>
            <span className="tw-text-label-secondary">
              {jointState?.velocity ?? 'Unknown'}
            </span>
          </div>
          <div className="tw-flex tw-gap-8 tw-items-center">
            <h5>MCU Temp:</h5>
            <span className="tw-text-label-secondary">
              {jointState?.mcuTemperature
                ? round(jointState.mcuTemperature, 2).toFixed(2)
                : '?'}
            </span>
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-flex mobile:tw-flex-col tw-gap-24 tw-h-full">
        <div className="desktop:tw-w-1/4 tw-flex tw-flex-col tw-gap-16 tw-h-full tw-overflow-auto">
          <h5 className="tw-heading-40 tw-pl-16">Actions</h5>
          <SettingsGroup className="tw-mb-24 tw-overflow-hidden">
            {runnables.map((action) => {
              const { key, name } = action;

              return (
                <SettingsGroupNavigationItem
                  className={cx('tw-py-8', {
                    'tw-surface-orange': selectedAction?.key === key,
                  })}
                  key={key}
                  onClick={() =>
                    setSelectedAction(() => {
                      if (!action) return null;

                      return {
                        ...action,
                        jointNumber: device.jointNumber,
                        serialNumber: device.serialNumber,
                        inputParams: [...action.inputParams],
                      };
                    })
                  }
                  label={name}
                />
              );
            })}
          </SettingsGroup>
        </div>
        <div className="desktop:tw-w-3/4 tw-flex tw-gap-10">
          <div className="desktop:tw-w-1/3">
            <AssemblyRunPanel selectedAction={selectedAction} />
          </div>
          <div className="desktop:tw-w-2/3 tw-pt-8">
            {selectedAction && (
              <AssemblyOutputPanel
                runnableOutput={runnableOutput[selectedAction.key]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
