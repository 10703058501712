import type { AssemblyRunnableInputParam } from '@sb/assembly';
import {
  SettingsGroupItem,
  NumberInput,
  Switch,
  TextInput,
} from '@sb/design-system';

type Props = {
  param: AssemblyRunnableInputParam;
  onChange: (newValue: any) => void;
};

export function AssemblyRunParameterInput({ param, onChange }: Props) {
  const { key, label, value, defaultValue, type, units } = param;

  const getInput = () => {
    switch (type) {
      case 'number':
        return (
          <NumberInput
            size={32}
            variant="Gray"
            className="tw-w-80"
            alignment="Right"
            value={Number(value ?? defaultValue)}
            decimalPlaces={4}
            onChange={(val) => onChange(Number(val))}
          />
        );
      case 'boolean':
        return (
          <Switch
            id="showAllSwitch"
            checked={value === true}
            onChange={(e) => onChange(e.target.checked)}
          />
        );
      case 'string':
        return (
          <TextInput
            size={32}
            variant="Gray"
            className="tw-w-[240px]"
            alignment="Right"
            value={`${value ?? defaultValue ?? ''}`}
            onChange={(val) => onChange(val)}
          />
        );
      default:
        return (
          <span className="tw-text-17 tw-font-regular tw-text-label-tertiary">
            Unsupported param type
          </span>
        );
    }
  };

  return (
    <SettingsGroupItem key={key}>
      <label className="tw-flex-1">{label}</label>
      {getInput()}
      {units && <span className="tw-w-52 tw-text-right">{units}</span>}
    </SettingsGroupItem>
  );
}
