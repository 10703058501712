import * as zod from 'zod';

import { JointType } from '@sb/firmware-interface/types';

// a device connected via CAN
export const ConnectedDevice = zod.object({
  jointNumber: zod.number().optional(),
  jointType: zod.nativeEnum(JointType),
  serialNumber: zod.number(),
  lastMessageReceieved: zod.number(),
});

export type ConnectedDevice = zod.infer<typeof ConnectedDevice>;
