import * as zod from 'zod';

import { AssemblyJoint } from './AssemblyJoint';
import type { AssemblyRunnableDetails } from './AssemblyRunnableData';
import { AssemblyRunnableState } from './AssemblyRunnableData';
import { ConnectedDevice } from './ConnectedDevice';

export interface AssemblyControllerToRCEvents {
  connectedDevices: (devices: ConnectedDevice[]) => void;
  jointStateUpdate: (state: (AssemblyJoint | null)[]) => void;
  runnableState: (state: AssemblyRunnableState | null) => void;
  outputStream: (data: {
    runnableKey: string;
    runId: string;
    message: string;
  }) => void;
}

export interface RCToAssemblyControllerEvents {
  run: (
    data: AssemblyRunnableDetails,
    callback: (error: string | null) => void,
  ) => void;
  kill: () => void;
}

export const AssemblyState = zod.object({
  connectedDevices: zod.array(ConnectedDevice),
  jointStates: zod.array(zod.nullable(AssemblyJoint)),
  runnableState: zod.nullable(AssemblyRunnableState),
  // output: zod.nullable(zod.string()),
});

export type AssemblyState = zod.infer<typeof AssemblyState>;
