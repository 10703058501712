import * as zod from 'zod';

import { JointType } from '@sb/firmware-interface/types';

export const AssemblyRunnableInputParam = zod.object({
  key: zod.string(),
  label: zod.string().optional(),
  description: zod.string().optional(),
  type: zod.enum(['number', 'string', 'boolean', 'list']),
  units: zod.string().optional(),
  value: zod
    .union([zod.number(), zod.string(), zod.boolean(), zod.array(zod.string())])
    .optional(),
  defaultValue: zod
    .union([zod.number(), zod.string(), zod.boolean(), zod.array(zod.string())])
    .optional(),
  required: zod.boolean().default(false).optional(),
  alwaysShow: zod.boolean().default(false).optional(),
});

export const AssemblyRunnableDetails = zod.object({
  key: zod.string(),
  name: zod.string(),
  description: zod.string().optional(),
  notes: zod.string().optional(),
  jointNumber: zod.number().optional(),
  serialNumber: zod.number().optional(),
  inputParams: zod.array(AssemblyRunnableInputParam),
});

export const AssemblyRunnableResult = zod.object({
  key: zod.string(),
  errors: zod.array(zod.string()),
  warnings: zod.array(zod.string()),
  pass: zod.boolean(),
});

export const AssemblyRunnableState = zod.object({
  runnableKey: zod.string(),
  runId: zod.string(),
  progress: zod.number().optional(),
  executionState: zod
    .enum(['NOT_STARTED', 'RUNNING', 'COMPLETE'])
    .default('NOT_STARTED'),
  successState: zod.enum(['PENDING', 'PASS', 'FAIL']).default('PENDING'),
  result: zod.optional(AssemblyRunnableResult),
});

export type AssemblyRunnableState = zod.infer<typeof AssemblyRunnableState>;
export type AssemblyRunnableInputParam = zod.infer<
  typeof AssemblyRunnableInputParam
>;
export type AssemblyRunnableResult = zod.infer<typeof AssemblyRunnableResult>;
export type AssemblyRunnableDetails = zod.infer<typeof AssemblyRunnableDetails>;

export const AssemblyToolRunnables: { [key: string]: AssemblyRunnableDetails } =
  {
    zeroJoint: {
      key: 'zeroJoint',
      name: 'Zero Joint',
      description: 'Zero the joint',
      notes: '## Zero Joint',
      inputParams: [
        {
          key: 'angle',
          label: 'Angle',
          units: 'deg',
          type: 'number',
          defaultValue: 0,
          required: false,
        },
      ],
    },
    assignJointNumber: {
      key: 'assignJointNumber',
      name: 'Assign Joint Number',
      description: 'Assign a joint number to the joint',
      notes: '## Assign Joint Number',
      inputParams: [
        {
          key: 'jointNumber',
          label: 'Joint Number',
          type: 'number',
          required: true,
        },
      ],
    },
    encoderCalibration: {
      key: 'encoderCalibration',
      name: 'Encoder Calibration',
      description: 'Calibrate the encoder(s)',
      notes: '## Encoder Calibration',
      inputParams: [
        {
          key: 'velocity',
          label: 'Velocity',
          type: 'number',
          units: 'deg/s',
          required: false,
        },
        {
          key: 'degrees',
          label: 'Total Rotation',
          type: 'number',
          units: 'deg',
          required: false,
        },
      ],
    },
    coggingCalibration: {
      key: 'coggingCalibration',
      name: 'Cogging Calibration',
      description:
        'Spins the joint extremely slow to create a LUT for cogging torque.',
      notes: '## Cogging Calibration',
      inputParams: [
        {
          key: 'velocity',
          label: 'Velocity',
          type: 'number',
          units: 'rad/s',
          required: false,
          defaultValue: 0.0005,
        },
        {
          key: 'rotations',
          label: 'Rotations',
          type: 'number',
          description: 'Number of input shaft rotations to spin',
          defaultValue: 3,
          required: false,
        },
        {
          key: 'printOnly',
          label: 'Print Only',
          type: 'boolean',
          description: 'If true will print the cogging torque to the console',
          defaultValue: false,
          required: false,
        },
      ],
    },
    stictionCalibration: {
      key: 'stictionCalibration',
      name: 'Stiction Calibration',
      description:
        'Finds the lowest duty cycle that can overcome stiction and spin the joint.',
      notes: '## Stiction Calibration',
      inputParams: [
        {
          key: 'stepSize',
          label: 'Duty Cycle Step Size',
          type: 'number',
          required: false,
          defaultValue: 0.001,
        },
        {
          key: 'maxDutyCycle',
          label: 'Max Duty Cycle',
          type: 'number',
          defaultValue: 0.05,
          required: false,
        },
        {
          key: 'minDutyCycle',
          label: 'Min Duty Cycle',
          type: 'number',
          defaultValue: 0.015,
          required: false,
        },
        {
          key: 'zeroTolerance',
          label: 'Tolerance',
          type: 'number',
          description:
            'How many readings of velocity: 0 to allow to be considered "passing"',
          defaultValue: 10,
          required: false,
        },
        {
          key: 'iterations',
          label: 'Iterations',
          type: 'number',
          description:
            'How many times to run calibration procedure. The lowest duty cycle will be used.',
          defaultValue: 3,
          required: false,
        },
      ],
    },
    jog: {
      key: 'jog',
      name: 'Jog',
      description: 'Jog the joint.',
      notes: '## Jog',
      inputParams: [
        {
          key: 'velocity',
          label: 'Velocity',
          type: 'number',
          units: 'rad/s',
          required: false,
          alwaysShow: true,
        },
        {
          key: 'dutyCycle',
          label: 'Duty Cycle',
          type: 'number',
          required: false,
          alwaysShow: true,
        },
        {
          key: 'torque',
          label: 'Torque',
          type: 'number',
          units: 'Nm',
          required: false,
        },
        {
          key: 'current',
          label: 'Current',
          type: 'number',
          units: 'amps',
          required: false,
        },
        {
          key: 'holdTime',
          label: 'Hold Time',
          type: 'number',
          required: false,
          units: 'ms',
          defaultValue: 10000,
          alwaysShow: true,
        },
        {
          key: 'rampTime',
          label: 'Ramp Time',
          type: 'number',
          required: false,
          units: 'ms',
          defaultValue: 250,
        },
        {
          key: 'usePid',
          label: 'Use PID',
          type: 'boolean',
          description: 'Use PID control to maintain velocity',
          defaultValue: false,
          required: false,
        },
        {
          key: 'expectEncoderErrors',
          label: 'Expect Encoder Errors',
          type: 'boolean',
          defaultValue: false,
          required: false,
        },
      ],
    },
    initialJointChecks: {
      key: 'initialJointChecks',
      name: 'Initial Joint Checks',
      description: 'Run initial checks on a motor-board.',
      notes: '## Initial Joint Checks',
      inputParams: [
        {
          key: 'rampTime',
          label: 'Ramp Time',
          type: 'number',
          required: false,
          units: 'ms',
          defaultValue: 250,
        },
        {
          key: 'current',
          label: 'Current',
          type: 'number',
          units: 'amps',
          required: false,
          defaultValue: 5,
        },
        {
          key: 'time',
          label: 'Time',
          description: 'Duration in seconds to spin the joint.',
          type: 'number',
          required: false,
          units: 'sec',
          defaultValue: 30,
        },
        {
          key: 'iqTolerance',
          label: 'IQ Tolerance',
          type: 'number',
          defaultValue: 0.2,
          required: false,
        },
        {
          key: 'idTolerance',
          label: 'ID Tolerance',
          type: 'number',
          defaultValue: 1.0,
          required: false,
        },
        {
          key: 'backlahTolerance',
          label: 'Backlash Tolerance',
          type: 'number',
          defaultValue: 1.0,
          required: false,
        },
        {
          key: 'speedTolerance',
          label: 'Speed Tolerance',
          type: 'number',
          defaultValue: 0.1,
          required: false,
        },
      ],
    },
    finalJointChecks: {
      key: 'finalJointChecks',
      name: 'Final Joint Checks',
      description:
        'Run the joint through a series of checks after assembly. Be ready to stop the joint if something goes wrong.',
      notes: '## Final Joint Checks',
      inputParams: [
        {
          key: 'velocity',
          label: 'Velocity',
          type: 'number',
          units: 'rad/s',
          required: false,
          defaultValue: 0.1,
        },
        {
          key: 'longVelocity',
          label: 'Long Velocity',
          description:
            'Velocity (rad/s) to move at for the second test, which rotates for the specified time',
          type: 'number',
          units: 'rad/s',
          required: false,
          defaultValue: 2.5,
        },
        {
          key: 'hallVelocity',
          label: 'Hall Velocity',
          description:
            ' Velocity (rad/s) to move at for the for hall test. moves 1/25th of a rotation',
          type: 'number',
          units: 'rad/s',
          required: false,
          defaultValue: 0.01,
        },
        {
          key: 'longTime',
          label: 'Long Time',
          description: 'Time (s) to move the joint for the second test.',
          type: 'number',
          units: 'sec',
          required: false,
          defaultValue: 30,
        },
      ],
    },
    setGraphable: {
      key: 'setGraphable',
      name: 'Set Graphable',
      description: 'Set a parameter on the joint.',
      notes: '## Set Graphable',
      inputParams: [
        {
          key: 'graphableName',
          label: 'Graphable Name',
          type: 'string',
          required: true,
        },
        {
          key: 'graphableValue',
          label: 'Value',
          type: 'number',
          required: true,
        },
      ],
    },
  };

export const getRunnablesByJointType = (jointType: JointType) => {
  switch (jointType) {
    case JointType.ShoulderJoint:
    case JointType.ElbowJoint:
    case JointType.WristJoint:
      return [
        AssemblyToolRunnables.zeroJoint,
        AssemblyToolRunnables.assignJointNumber,
        AssemblyToolRunnables.jog,
        AssemblyToolRunnables.encoderCalibration,
        AssemblyToolRunnables.initialJointChecks,
        AssemblyToolRunnables.finalJointChecks,
        AssemblyToolRunnables.coggingCalibration,
        AssemblyToolRunnables.stictionCalibration,
        AssemblyToolRunnables.setGraphable,
      ];
    default:
      return [];
  }
};
