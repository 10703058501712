import * as zod from 'zod';

// A joint connected via CAN
export const AssemblyJoint = zod.object({
  jointNumber: zod.number().min(0),
  position: zod.number().optional(),
  velocity: zod.number().optional(),
  amperageTorque: zod.number().optional(),
  windupTorque: zod.number().optional(),
  brake: zod.boolean().optional(),
  mcuTemperature: zod.number().optional(),
});

export type AssemblyJoint = zod.infer<typeof AssemblyJoint>;
