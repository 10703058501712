import cx from 'classnames';
import { useRouter } from 'next/router';

import type { ConnectedDevice } from '@sb/assembly';
import { Button, Icon } from '@sb/design-system';

import { AssemblyDeviceDropDown } from './AssemblyDeviceDropdown';

interface AppHeaderProps {
  children: React.ReactNode;
  selected: ConnectedDevice | null;
  onSelect: (device: ConnectedDevice) => void;
}

export function AssemblyHeader({
  children,
  selected,
  onSelect,
}: AppHeaderProps) {
  const { push } = useRouter();

  return (
    <header
      className={cx(
        'tw-fixed',
        'tw-top-0',
        'tw-left-0',
        'tw-right-0',
        'tw-h-[--page--app-bar-height]',
        'tw-surface-elevated',
        'tw-flex',
        'tw-items-center',
        'tw-border-b',
        'tw-border-metal-30',
        'dark:tw-border-divider-primary',
        'tw-z-20',
        'tw-gap-16',
        // extend the header above the top of the viewport - on the iPad app this area is visible behind the OS status bar
        'tw-box-content',
        'tw-border-t-[--surface-elevated]',
        'tw-border-t-[40px]',
        '-tw-mt-40',
      )}
    >
      <div
        className={cx(
          'tw-flex',
          'tw-flex-1',
          'tw-items-center',
          'tw-gap-16',
          'tw-pl-16',
          'tw-overflow-hidden',
          'tw-whitespace-nowrap',
          'tw-justify-end',
        )}
      >
        {children}
        <div className="tw-flex-1" />
      </div>

      <div className="tw-flex-none">
        <Button
          variant="Filled"
          color="Red"
          onClick={() => {}}
          data-testid="app-header-estop-button"
        >
          <Icon kind="circleSlash" />
          <span>E-Stop</span>
        </Button>
      </div>

      <div
        className={cx(
          'tw-flex',
          'tw-flex-1',
          'tw-items-center',
          'tw-gap-16',
          'tw-pr-16',
          'tw-overflow-hidden',
        )}
      >
        <div className="tw-flex-1" />
        <Button
          className="tw-icon-28 tw-text-label-secondary"
          variant="Flat"
          role="button"
          aria-label="Home"
          onClick={() => push('/control-panel')}
        >
          <Icon kind="sliderHorizontal" className="-tw-ml-4" />
          <span>Control Panel</span>
        </Button>
        <AssemblyDeviceDropDown selected={selected} onSelect={onSelect} />
      </div>
    </header>
  );
}
