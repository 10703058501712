import Head from 'next/head';
import { useMemo } from 'react';

import { usePageRobotAndRoutine } from '@sbrc/hooks';
import { getPageTitle } from '@sbrc/utils';

import AppBar from '../app-bar/AppBar';
import AppHeaderNav from '../app-bar/AppHeaderNav';

export interface PrivatePageLayoutProps {
  children: React.ReactNode;
  title: string;
}

export function PrivatePageLayoutInner({
  children,
  title,
}: PrivatePageLayoutProps) {
  const pageTitle = useMemo(() => getPageTitle(title), [title]);

  const { robot, routine } = usePageRobotAndRoutine();

  return (
    <main>
      <Head>
        <title>{pageTitle}</title>
      </Head>

      <AppHeaderNav />
      <AppBar robot={robot} routine={routine} />

      <div className="tw-m-24 tw-mb-76">{children}</div>
    </main>
  );
}
