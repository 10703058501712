import { useRouter } from 'next/router';

import { Icon } from '@sb/design-system';

interface BackButtonProps {
  onClick?: () => void;
}

export function BackButton({ onClick }: BackButtonProps) {
  const { back } = useRouter();

  return (
    <Icon
      kind="arrowLeft"
      className="tw-icon-28 tw-text-label-secondary"
      role="button"
      aria-label="Back"
      onClick={onClick ?? back}
    />
  );
}
