import PrivatePage from '../user-sessions/PrivatePage';

import type { PrivatePageLayoutProps } from './PrivatePageLayoutInner';
import { PrivatePageLayoutInner } from './PrivatePageLayoutInner';

/**
 * Shared layout for private pages (routes requiring authentication).
 */
const PrivatePageLayout = (props: PrivatePageLayoutProps) => {
  return (
    <PrivatePage>
      <PrivatePageLayoutInner {...props} />
    </PrivatePage>
  );
};

export default PrivatePageLayout;
