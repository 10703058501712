import { AssemblyToolsView } from '@sbrc/components/assembly-tools/AssemblyToolsView';
import PrivatePageLayout from '@sbrc/components/layouts/PrivatePageLayout';

export const AssemblyToolsPage = () => {
  return (
    <PrivatePageLayout title="Robby">
      <AssemblyToolsView assemblyToolsName="Robby" />
    </PrivatePageLayout>
  );
};

export default AssemblyToolsPage;
