import { isEqual, memoize } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import type { Socket } from 'socket.io-client4';
import { io } from 'socket.io-client4';

import type {
  AssemblyState,
  AssemblyControllerToRCEvents,
  RCToAssemblyControllerEvents,
  AssemblyRunnableDetails,
} from '@sb/assembly';
import type { ConnectedDevice } from '@sb/assembly/ConnectedDevice';
import { makeNamespacedLog } from '@sb/log';
import { EventEmitter, six, wait } from '@sb/utilities';
import type { ListenerType } from '@sb/utilities/src/EventEmitter';
import { API_ENDPOINT, globalCache } from '@sbrc/utils';

const log = makeNamespacedLog('AssemblyControllerHandle');

export class AssemblyControllerHandle {
  private socket: Socket<
    AssemblyControllerToRCEvents,
    RCToAssemblyControllerEvents
  > | null = null;

  private connectionStatus = new BehaviorSubject<any>({
    kind: 'constructing',
  });

  private state: AssemblyState = {
    connectedDevices: [],
    jointStates: six(null),
    runnableState: null,
  };

  private assemblyStateUpdate = new EventEmitter<AssemblyState>();

  private runnableOutput = new EventEmitter<{
    runnableOutput: {
      runnableKey: string;
      runId: string;
      message: string;
    };
  }>();

  public constructor() {
    this.connect();
  }

  public listenForStateUpdates<K extends keyof AssemblyState>(
    event: K,
    callback: ListenerType<AssemblyState, K>, // (message: AssemblyState[K]) => void,
  ): () => void {
    return this.assemblyStateUpdate.on(event, callback);
  }

  public listenForRunnableOutput(
    callback: ListenerType<
      {
        runnableOutput: {
          runnableKey: string;
          runId: string;
          message: string;
        };
      },
      'runnableOutput'
    >,
  ): () => void {
    return this.runnableOutput.on('runnableOutput', callback);
  }

  public getState<K extends keyof AssemblyState>(event: K) {
    return this.state[event];
  }

  public execute(
    runnable: AssemblyRunnableDetails,
    callback: (error: string | null) => void,
  ) {
    this.assemblyStateUpdate.emit('runnableState', {
      executionState: 'NOT_STARTED',
      runnableKey: runnable.key,
      runId: '',
      successState: 'PENDING',
    });

    this.socket?.emit('run', runnable, callback);
  }

  public kill() {
    this.socket?.emit('kill');
  }

  private connect() {
    // don't do anything when running on server
    if (typeof window === 'undefined') {
      return;
    }

    log.info(`uncategorized`, 'Connecting...');
    this.connectionStatus.next({ kind: 'connecting' });

    this.socket = io(`${API_ENDPOINT}`, {
      path: '/assembly-controller-bot-ws/',
    });

    const connectingTimeoutID = setTimeout(() => {
      if (!this.socket?.connected) {
        log.info(`uncategorized`, 'Timed out while connecting');
        this.socket?.close();
      }
    }, 5_000);

    const destructors: Array<() => void> = [];

    // teardown is memoized so it is only called once
    const teardownAndReconnect = memoize(async () => {
      log.info(`uncategorized`, 'Teardown', {
        connectionState: this.socket?.connected,
      });

      if (this.socket?.connected) {
        this.socket?.close();
      }

      for (const destructor of destructors) {
        destructor();
      }

      if (this.getConnectionStatus() !== 'connecting') {
        this.connectionStatus.next({ kind: 'disconnected' });
      }

      await wait(1000);
      this.connect();
    });

    this.socket?.on('connect', () => {
      log.info(`uncategorized`, 'Connected');
      clearTimeout(connectingTimeoutID);
      this.connectionStatus.next({ kind: 'connected' });
    });

    this.socket?.on(
      'connectedDevices',
      (connectedDevices: ConnectedDevice[]) => {
        if (isEqual(this.state.connectedDevices, connectedDevices)) {
          return;
        }

        this.state.connectedDevices = { ...connectedDevices };
        this.assemblyStateUpdate.emit('connectedDevices', connectedDevices);
      },
    );

    this.socket?.on('jointStateUpdate', (jointStates) => {
      if (isEqual(this.state.jointStates, jointStates)) {
        return;
      }

      this.state.jointStates = { ...jointStates };
      this.assemblyStateUpdate.emit('jointStates', this.state.jointStates);
    });

    this.socket?.on('runnableState', (runnableState) => {
      if (isEqual(this.state.runnableState, runnableState)) {
        return;
      }

      this.state.runnableState = runnableState;
      this.assemblyStateUpdate.emit('runnableState', this.state.runnableState);
    });

    this.socket?.on('outputStream', (data) => {
      this.runnableOutput.emit('runnableOutput', data);
    });

    this.socket?.on('disconnect', () => {
      this.connectionStatus.next({ kind: 'disconnected' });
      teardownAndReconnect();
    });
  }

  public getConnectionStatus(): string {
    return this.connectionStatus.value;
  }

  public onConnectionChange(
    cb: (connectionStatus: string) => void,
  ): () => void {
    const subscription = this.connectionStatus.subscribe(cb);

    return () => subscription.unsubscribe();
  }
}

export function getAssemblyControllerHandle(): AssemblyControllerHandle {
  return globalCache(`assemblyControllerHandle`, () => {
    // TODO fix double socket connections on hot reload.
    const websocketConnection = new AssemblyControllerHandle();

    return websocketConnection;
  });
}
