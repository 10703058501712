import cx from 'classnames';
import { useContext, useEffect, useState } from 'react';

import type {
  AssemblyRunnableDetails,
  AssemblyRunnableInputParam,
} from '@sb/assembly';
import { SettingsGroup, Button, Icon, Switch } from '@sb/design-system';
import { getAssemblyControllerHandle } from '@sbrc/services/assembly-client';

import { AssemblyContext } from './AssemblyContext';
import { AssemblyRunParameterInput } from './AssemblyRunParameterInput';

type Props = {
  selectedAction?: AssemblyRunnableDetails | null;
  onExecute?: () => void;
};

export function AssemblyRunPanel(props: Props) {
  const { selectedAction, onExecute } = props;
  const assemblyConnection = getAssemblyControllerHandle();
  const { runnableState } = useContext(AssemblyContext);

  const [inputParams, setInputParams] = useState<AssemblyRunnableInputParam[]>(
    selectedAction?.inputParams ?? [],
  );

  const [showHiddenParms, setShowHiddenParms] = useState(false);

  useEffect(() => {
    setInputParams(selectedAction?.inputParams ?? []);
  }, [selectedAction]);

  const executeClick = () => {
    if (!selectedAction) return;

    onExecute?.();

    assemblyConnection.execute(selectedAction, (_error) => {});
  };

  const killClick = () => {
    assemblyConnection.kill();
  };

  const canExecute = () => {
    if (!selectedAction) return false;

    if (runnableState && runnableState.executionState !== 'COMPLETE')
      return false;

    let allParamsValid = true;

    inputParams.forEach((param) => {
      if (
        param.required &&
        param.value === undefined &&
        param.defaultValue === undefined
      ) {
        allParamsValid = false;
      }
    });

    return allParamsValid;
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-16 tw-h-full tw-pt-8">
      {!selectedAction && (
        <div className="tw-flex tw-justify-center">
          <h5 className="tw-heading-40 tw-pl-16">No Action Selected</h5>
        </div>
      )}
      {selectedAction && (
        <>
          <div className="tw-flex tw-gap-20 tw-items-center tw-justify-between">
            <h5 className="tw-text-20">{selectedAction.name}</h5>
            {(runnableState === null ||
              runnableState.executionState === 'COMPLETE') && (
              <Button
                disabled={!canExecute()}
                variant="Filled"
                onClick={executeClick}
              >
                <Icon kind="play" />
                <span>Execute</span>
              </Button>
            )}
            {runnableState && runnableState?.executionState !== 'COMPLETE' && (
              <Button variant="Filled" color="Red" onClick={killClick}>
                <Icon kind="stop" />
                <span>Stop</span>
              </Button>
            )}
          </div>
          <div className="tw-flex tw-gap-20 tw-items-center tw-justify-between">
            <span className="tw-text-label-tertiary tw-text-13">
              {selectedAction.description}
            </span>
          </div>
          <div>
            <div className="tw-flex tw-gap-20 tw-items-center">
              <h5 className="tw-heading-40 tw-pl-16">Parameters</h5>
              <label
                htmlFor="showAllSwitch"
                className="tw-ml-auto tw-text-label-tertiary tw-text-13"
              >
                Show All
              </label>
              <Switch
                id="showAllSwitch"
                checked={showHiddenParms}
                onChange={(e) => setShowHiddenParms(e.target.checked)}
              />
            </div>
            <SettingsGroup className="tw-mb-24">
              {inputParams
                .filter((param) => {
                  if (showHiddenParms) return true;

                  return param.alwaysShow || param.required;
                })
                .map((param) => (
                  <AssemblyRunParameterInput
                    key={param.key}
                    param={param}
                    onChange={(val) => {
                      const params = [...inputParams];

                      const existing = params.find((p) => p.key === param.key);

                      if (existing) existing.value = val;
                      setInputParams(params);
                    }}
                  />
                ))}
            </SettingsGroup>
            {runnableState?.runnableKey === selectedAction.key && (
              <div className="tw-flex-col tw-border-t tw-p-8">
                {runnableState && (
                  <div className="tw-flex tw-items-center tw-justify-center">
                    {runnableState.executionState === 'RUNNING' && (
                      <span
                        className={cx('tw-heading-60', `tw-text-secondary`)}
                      >
                        Running...
                      </span>
                    )}
                    {runnableState.result && (
                      <span
                        className={cx(
                          'tw-heading-60',
                          `tw-text-${
                            runnableState.result.pass ? 'green' : 'red'
                          }`,
                        )}
                      >
                        {runnableState.result.pass ? 'Passed' : 'Failed'}
                      </span>
                    )}
                  </div>
                )}
                {runnableState.result?.errors &&
                  runnableState.result.errors.length > 0 && (
                    <h5 className="tw-heading-40">Error Messages:</h5>
                  )}
                {runnableState.result?.errors?.map((error) => (
                  <div className="tw-text-red">{error}</div>
                ))}
                {runnableState.result?.warnings &&
                  runnableState.result.warnings.length > 0 && (
                    <h5 className="tw-heading-40">Warnings:</h5>
                  )}
                {runnableState.result?.warnings?.map((error) => (
                  <div className="tw-text-label-secondary">{error}</div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
