import { BackButton } from './BackButton';
import { HomeButton } from './HomeButton';

interface NavAndTitleProps {
  isHomeButtonHidden?: boolean;
  onReturn?: () => void;
  title: string;
}

export default function NavAndTitle({
  isHomeButtonHidden,
  onReturn,
  title,
}: NavAndTitleProps) {
  return (
    <>
      {!isHomeButtonHidden && <HomeButton />}
      <BackButton onClick={onReturn} />
      {title && (
        <h4 className="tw-text-17 tw-font-medium tw-text-ellipsis">{title}</h4>
      )}
    </>
  );
}
