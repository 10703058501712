import cx from 'classnames';
import { useEffect, useState } from 'react';

import type {
  AssemblyJoint,
  AssemblyRunnableState,
  ConnectedDevice,
} from '@sb/assembly';
import { JointType } from '@sb/firmware-interface/types';
import { getAssemblyControllerHandle } from '@sbrc/services/assembly-client';

import { NavAndTitle } from '../app-bar';

import { AssemblyContext } from './AssemblyContext';
import { AssemblyDeviceDetails } from './AssemblyDeviceDetails';
import { AssemblyHeader } from './AssemblyHeader';

interface AssemblyToolsViewProps {
  assemblyToolsName: string;
}

export function AssemblyToolsView({
  assemblyToolsName,
}: AssemblyToolsViewProps) {
  const assemblyConnection = getAssemblyControllerHandle();

  const [connectedDevices, setConnectedDevices] = useState<ConnectedDevice[]>(
    assemblyConnection.getState('connectedDevices'),
  );

  const [jointStates, setJointStates] = useState<(AssemblyJoint | null)[]>(
    assemblyConnection.getState('jointStates'),
  );

  const [runnableState, setRunnableState] =
    useState<AssemblyRunnableState | null>(
      assemblyConnection.getState('runnableState'),
    );

  const [selectedDevice, setSelectedDevice] = useState<ConnectedDevice | null>(
    null,
  );

  useEffect(() => {
    const unsubscribeConnectedDevice = assemblyConnection.listenForStateUpdates(
      'connectedDevices',
      (devices) => {
        setConnectedDevices(devices);

        if (selectedDevice === null) {
          const firstMotorJoint = devices.find(
            (device) =>
              device.jointType === JointType.ShoulderJoint ||
              device.jointType === JointType.ElbowJoint ||
              device.jointType === JointType.WristJoint,
          );

          setSelectedDevice(firstMotorJoint ?? null);
        } else if (
          !devices.some(
            (device) => device.serialNumber === selectedDevice.serialNumber,
          )
        ) {
          setSelectedDevice(null);
        }
      },
    );

    const unsubscribeJointState = assemblyConnection.listenForStateUpdates(
      'jointStates',
      setJointStates,
    );

    const unsubscribeRunnable = assemblyConnection.listenForStateUpdates(
      'runnableState',
      setRunnableState,
    );

    return () => {
      unsubscribeConnectedDevice();
      unsubscribeJointState();
      unsubscribeRunnable();
    };
  }, [assemblyConnection, selectedDevice]);

  return (
    <main className={cx('tw-w-full', 'tw-h-full')}>
      <AssemblyContext.Provider
        value={{ connectedDevices, jointStates, runnableState }}
      >
        <AssemblyHeader selected={selectedDevice} onSelect={setSelectedDevice}>
          <NavAndTitle isHomeButtonHidden title={assemblyToolsName} />
        </AssemblyHeader>
        <div
          className={cx(
            'tw-w-full',
            'tw-flex',
            'mobile:tw-flex-col',
            'tw-gap-24',
            'tw-h-full',
          )}
        >
          <div className="tw-flex tw-flex-col tw-gap-16 tw-w-full tw-h-full tw-overflow-auto">
            {selectedDevice === null && (
              <p>Select a joint to view firmware details</p>
            )}
            {selectedDevice && (
              <AssemblyDeviceDetails device={selectedDevice} />
            )}
          </div>
        </div>
      </AssemblyContext.Provider>
    </main>
  );
}
