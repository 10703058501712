import Anser from 'anser';
import type { ReactNode } from 'react';
import { createElement, useEffect, useRef, useState } from 'react';

// create css properties for ansi bundle
// from https://github.com/nteract/ansi-to-react/
function createStyleForAnsi(bundle: Anser.AnserJsonEntry): React.CSSProperties {
  const style: React.CSSProperties = {};

  if (bundle.bg) {
    style.backgroundColor = `rgb(${bundle.bg})`;
  }

  if (bundle.fg) {
    style.color = `rgb(${bundle.fg})`;
  }

  switch (bundle.decoration) {
    case 'bold':
      style.fontWeight = 'bold';
      break;
    case 'dim':
      style.opacity = '0.5';
      break;
    case 'italic':
      style.fontStyle = 'italic';
      break;
    case 'hidden':
      style.visibility = 'hidden';
      break;
    case 'strikethrough':
      style.textDecoration = 'line-through';
      break;
    case 'underline':
      style.textDecoration = 'underline';
      break;
    case 'blink':
      style.textDecoration = 'blink';
      break;
    default:
      break;
  }

  return style;
}

type Props = {
  runnableOutput: { runId: string; output: string[] }[] | null;
};

export function AssemblyOutputPanel({ runnableOutput }: Props) {
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [scrollLock, setScrollLock] = useState(true);
  const lines: ReactNode[] = [];
  let currentLine: ReactNode[] = [];

  const ansiStyle = (line: string, outerIndex: number) => {
    const ansiJson = Anser.ansiToJson(line, {
      remove_empty: true,
    });

    ansiJson.forEach((ansi, index) => {
      if (ansi.content === '\n') {
        lines.push(
          // eslint-disable-next-line react/no-array-index-key
          <div key={`line_${outerIndex}_${index}`}>{currentLine}</div>,
        );

        currentLine = [];
      }

      const style = createStyleForAnsi(ansi);

      const el = createElement(
        'span',
        // eslint-disable-next-line react/no-array-index-key
        { style, key: `ansi_${index}` },
        ansi.content,
      );

      currentLine.push(el);
    });
  };

  runnableOutput?.[runnableOutput.length - 1].output.forEach(
    (outputBlob, outerIndex) => {
      ansiStyle(outputBlob, outerIndex);
    },
  );

  useEffect(() => {
    if (scrollLock) bottomRef.current?.scrollIntoView();
  }, [runnableOutput, scrollLock]);

  return (
    <div
      className="tw-w-full tw-flex tw-flex-col tw-h-full tw-overflow-auto tw-max-h-[80vh]"
      onScroll={() => setScrollLock(false)}
    >
      <code className="tw-flex tw-flex-col tw-w-full tw-p-8 tw-dark tw-surface-secondary">
        {!runnableOutput && (
          <span className="tw-text-label-tertiary">Waiting for output...</span>
        )}
        {runnableOutput && lines}
      </code>
      <div ref={bottomRef} />
    </div>
  );
}
